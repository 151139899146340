import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

/**
 * COMPONENT
 */
const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="home-everything-div wallpaper">
      <div
        id="carouselExampleControls"
        className="carousel slide"
        data-ride="carousel"
        data-interval="5500"
        data-pause="false"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              className="d-block w-100"
              src="pharmacy-bottles.jpg"
              alt="First slide"
            />
          </div>
          <div className="carousel-item">
            <img
              className="d-block w-100"
              src="coffee-books.jpg"
              alt="Second slide"
            />
          </div>
        </div>
        <a
          className="carousel-control-prev"
          href="#carouselExampleControls"
          role="button"
          data-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleControls"
          role="button"
          data-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
        </a>
      </div>
      <div className="login-header home-top-section home-top-content">
        <div className="header-main">
          <p className="home-paragraphs home-text-sizing">
            Resident First Consultants is a Pharmacy Consulting Company founded
            in Ohio by Rob Shulman RPh CGP FASCP. We specialize in providing
            pharmacy-related consulting services to our seniors residing in a
            multitude of setting from the community to skilled nursing
            facilities. Our seasoned consultants, may of whom are Board
            Certified Geriatric Pharmacists (BCGP), have years of experience in
            the Long-Term Care setting. This means years of clinical and
            regulatory experience working for you!
          </p>
        </div>
        <div className="section-team">
          <div>
            <h3>Meet our experts</h3>
            <div>
              <button className="btn btn-default contact-button">
                <Link className="make-text-white" to="/about">
                  Our Team
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="grey">
        <div className="header-main">
          <div className="header-main-sub-div">
            <img src="rx.png" className="home-vector-icons" />
            <h2 className="make-me-blue">LTC PHARMACIES</h2>
            <div className="home-p-tags">
              <p>
                Trust us like others do with your most valuable asset: your
                customers! <br />
                Learn how to save time and resources while maximizing your
                customer service footprint.
              </p>
              <button className="btn btn-default more-info-button">
                <Link className="make-text-white" to="/pharmacy-services">
                  Learn More
                </Link>
              </button>
            </div>
          </div>
          <div className="header-main-sub-div">
            <img src="pharmacy-icon.png" className="home-vector-icons" />
            <h2 className="make-me-blue">FACILITIES</h2>
            <div className="home-p-tags">
              <p>
                Partner with a trusted knowledge and service based pharmacy
                consulting company that has your best interest in mind on
                multiple levels. Click below to view our facility services.{' '}
                <br />
              </p>
              <button className="btn btn-default more-info-button">
                <Link className="make-text-white" to="/facility-services">
                  Learn More
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="login-header home-hire-our">
        <div className="header-main">
          <h2>REQUEST A QUOTE OR SCHEDULE A CONSULTATION TODAY</h2>
          <hr className="header-line" />
          <button className="btn btn-default contact-button">
            <Link className="make-text-white" to="/contact">
              Contact
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
