import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateTheAccountUser } from '../store/account';

export const Account = () => {
  const { username } = useSelector((state) => state.auth);
  const { email } = useSelector((state) => state.auth);
  const [userEmail, setUserEmail] = useState(email);
  const [isSuccess, setIsSuccess] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function passwordCheck(password) {
    const containsNumber = (str) => /\d/.test(str);
    const containsCapitalLetter = (str) => /[A-Z]/.test(str);
    const containsSymbol = (str) => /[^\w\s]/.test(str);

    if (password.length <= 7) {
      alert('Password must be 8 characters or greater');
      return false;
    }
    if (containsNumber(password) != true) {
      alert('Password must contain at least one number');
      return false;
    }
    if (containsCapitalLetter(password) != true) {
      alert('Password must contain one capital letter');
      return false;
    }
    if (containsSymbol(password) != true) {
      alert('Password must contain at least one symbol');
      return false;
    }
    return true;
  }

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const emailToUpdate = evt.target.email;
    const passwordToUpdate = evt.target.password;
    let whatToUpdate = '';

    if (emailToUpdate.value && passwordToUpdate.value) {
      whatToUpdate = 'both';
      if (passwordCheck(passwordToUpdate.value) === true) {
        setIsSuccess(true);
        dispatch(
          updateTheAccountUser([
            username,
            whatToUpdate,
            emailToUpdate.value,
            passwordToUpdate.value,
          ])
        );
        emailToUpdate.value = '';
        passwordToUpdate.value = '';
        setTimeout(() => {
          setIsSuccess(false);
        }, 3500);
      }
    } else if (passwordToUpdate.value) {
      whatToUpdate = 'password';
      if (passwordCheck(passwordToUpdate.value) === true) {
        setIsSuccess(true);
        dispatch(
          updateTheAccountUser([
            username,
            whatToUpdate,
            'blank',
            passwordToUpdate.value,
          ])
        );
        passwordToUpdate.value = '';
        setTimeout(() => {
          setIsSuccess(false);
        }, 3500);
      }
    } else if (emailToUpdate.value) {
      setIsSuccess(true);
      whatToUpdate = 'email';
      dispatch(
        updateTheAccountUser([
          username,
          whatToUpdate,
          emailToUpdate.value,
          'blank',
        ])
      );
      emailToUpdate.value = '';
      setTimeout(() => {
        setIsSuccess(false);
      }, 3500);
    } else {
      alert('Please ensure the form is filled out correctly.');
    }
  };

  return (
    <div className="login-header">
      <div className="header-main contact-form">
        <h1>ACCOUNT SETTINGS</h1>
        <hr className="header-line-about" />
        <div className="contact-form">
          <p>
            {`Welcome to your account page ${username}! Here you can update your email or
            password using the form below.`}
          </p>
          <form onSubmit={handleSubmit}>
            <label>Email</label>
            <input
              className="larger-box-size"
              name="email"
              type="text"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
            />
            <label>Password</label>
            <input type="text" name="password" className="larger-box-size" />
            <div className="password-requirements-div">
              <div className="password-requirements">
                Your new password must: <br />
                <ul>
                  <li>
                    Be at least 8 characters long <br />
                  </li>
                  <li>
                    Have one number <br />
                  </li>
                  <li>
                    Have one capital letter <br />
                  </li>
                  <li>
                    Include one symbol <br />
                  </li>
                </ul>
              </div>
            </div>
            <button className="btn btn-default contact-button">Submit</button>
            {isSuccess ? <p style={{ color: 'green' }}>Success!</p> : ''}
          </form>
        </div>
      </div>
    </div>
  );
};
