import React, { useEffect } from 'react';
import { fetchSingleFacility } from '../store/single-facility';
import { fetchSpecificUsers } from '../store/all-users';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

export const SingleFacility = () => {
  const dispatch = useDispatch();
  const singleFacility = useSelector((state) => state.singleFacility);
  const allTheUsers = useSelector((state) => state.users);

  const thisURL = window.location.href;
  const facilityIdArr = thisURL.split('/');
  const facilityIdFromUrl = facilityIdArr.pop();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchSingleFacility(facilityIdFromUrl));
    dispatch(fetchSpecificUsers(facilityIdFromUrl));
  }, []);

  return (
    <div className="admin-users-div">
      <div className="back-to-dashboard-div2">
        <Link to="/facilities" className="back-to-dashboard">
          {'⬅ back to all facilities'}
        </Link>
      </div>
      <div>
        {singleFacility.map((facility) => {
          return (
            <div key={facility.id}>
              <h1 className="header-admin">{facility.name}</h1>
              <div>
                <form>
                  <h4>Current assigned users: </h4>
                  {allTheUsers.map((user) => {
                    return (
                      <p key={user.id}>
                        {user.id}. {user.username}
                      </p>
                    );
                  })}
                </form>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
