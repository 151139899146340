import axios from 'axios';

const SHOW_FACILITIES = 'SHOW_FACILITIES';
const SHOW_SPECIFIC_FACILITIES = 'SHOW_SPECIFIC_FACILITIES';
const DELETE_FACILITY = 'DELETE_FACILITY';
const UPDATE_FACILITY = 'UPDATE_FACILITY';

const showFacilities = (facilities) => {
  return {
    type: SHOW_FACILITIES,
    allFacilities: facilities,
  };
};

const showSpecificFacilities = (specificFacilities) => {
  return {
    type: SHOW_SPECIFIC_FACILITIES,
    specificFacilities: specificFacilities,
  };
};

const deleteFacility = (facility) => {
  return {
    type: DELETE_FACILITY,
    facilityToRemove: facility,
  };
};

const updateFacility = (facility) => {
  return {
    type: UPDATE_FACILITY,
    facilityToUpdate: facility,
  };
};

// THUNKS
export const fetchFacilities = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get('/api/facilities');
      const facilities = response.data;
      dispatch(showFacilities(facilities));
    } catch (error) {
      console.log('Sorry not able to fetch facilities', error);
    }
  };
};

export const fetchSpecificFacilities = (id) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/api/users/${id}/facilities`);
      const specificFacilities = response.data;
      dispatch(showSpecificFacilities(specificFacilities));
    } catch (error) {
      console.log('Sorry not able to fetch facilities', error);
    }
  };
};

export const addFacility = (name) => async (dispatch) => {
  try {
    const res = await axios.post('/api/facilities', {
      name,
    });
  } catch (error) {
    return error;
  }
};

export const deleteTheFacility = (id) => {
  return async (dispatch) => {
    try {
      const response = await axios.delete(`/api/facilities/${id}`);
      const facilityRemoved = response.data;

      dispatch(deleteFacility(facilityRemoved));
    } catch (error) {
      console.log('Sorry not able to delete this facility', error);
    }
  };
};

export const updateTheFacility = (updatedFacilityData) => {
  return async (dispatch) => {
    try {
      const res = await axios.put('/api/facilities', updatedFacilityData);
      dispatch(updateFacility(res.data));
    } catch (error) {
      console.log('Sorry not able to update facility', error);
    }
  };
};

// REDUCER
export default function allFacilitiesReducer(state = [], action) {
  switch (action.type) {
    case SHOW_FACILITIES:
      return action.allFacilities;
    case SHOW_SPECIFIC_FACILITIES:
      return action.specificFacilities;
    case DELETE_FACILITY:
      return state.filter(
        (facility) => facility.id !== action.facilityToRemove.id
      );
    case UPDATE_FACILITY:
      return state;
    default:
      return state;
  }
}
