import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchDocuments,
  addDocument,
  deleteTheDocument,
} from '../store/antibiotic';

export const Antibiotic = () => {
  const dispatch = useDispatch();
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const allDocuments = useSelector((state) => state.allDocuments);
  const [documentId, setDocumentId] = useState(0);
  const [deleteMe, setDeleteMe] = useState(false);
  const [formAction, setFormAction] = useState('');
  const [godMode, setGodMode] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchDocuments());
  }, []);

  const addData = (evt) => {
    evt.preventDefault();
    const title = evt.target.title;
    const link = evt.target.link;

    if (title.value && link.value) {
      dispatch(addDocument({ title: title.value, link: link.value }));

      title.value = '';
      link.value = '';
    } else {
      alert('Please fill out all fields');
    }
  };

  const handleDelete = (evt) => {
    evt.preventDefault();

    if (formAction === 'delete') {
      dispatch(deleteTheDocument(documentId));
      setDeleteMe(false);
      setFormAction('');
    }
  };

  const handleGodMode = () => {
    if (godMode) {
      setGodMode(false);
    } else {
      setGodMode(true);
    }
  };

  return (
    <div className="login-header move-footer">
      {isAdmin ? (
        <div className="header-main">
          <h1 className="antibiotic-header">
            Antibiotic Stewardship Resources
          </h1>
          <hr className="header-line-antibiotic" />
          <button
            onClick={handleGodMode}
            className="btn btn-default contact-button godMode"
          >
            Toggle Admin God Mode
          </button>
          {godMode ? (
            <div>
              <h4>Add Resource:</h4>
              <form onSubmit={addData}>
                <label>Title: </label>
                <input type="text" name="title" />
                <label>Link: </label>
                <input
                  type="text"
                  name="link"
                  placeholder="include full URL path"
                />
                <button>Add</button>
              </form>
              <div className="resource-list">
                {allDocuments.map((document, index) => {
                  return (
                    <div key={index}>
                      <form onSubmit={handleDelete}>
                        <h4 className="resource-item">
                          {index + 1}.{' '}
                          <a
                            role="link"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={document.link}
                          >
                            {document.title}
                          </a>
                          <button
                            className="resource-delete-button"
                            onClick={() => {
                              setDeleteMe(true);
                              setDocumentId(document.id);
                            }}
                          >
                            Delete
                          </button>
                        </h4>

                        {deleteMe && document.id === documentId ? (
                          <div>
                            <p>
                              Are you sure you want to DELETE this document:{' '}
                              {''}
                              {document.title}?
                            </p>
                            <button onClick={() => setFormAction('delete')}>
                              Confirm Delete
                            </button>
                            <button onClick={() => setDeleteMe(false)}>
                              Cancel
                            </button>
                          </div>
                        ) : (
                          ''
                        )}
                      </form>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div>
              {allDocuments.map((document, index) => {
                return (
                  <div key={index}>
                    <form onSubmit={handleDelete}>
                      <h4 className="resource-item">
                        {index + 1}.{' '}
                        <a
                          role="link"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={document.link}
                        >
                          {document.title}
                        </a>
                      </h4>
                    </form>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      ) : (
        <div className="header-main">
          <h1>Antibiotic Stewardship Resources</h1>
          {allDocuments.map((document, index) => {
            return (
              <div key={index}>
                <form onSubmit={handleDelete}>
                  <h4 className="resource-item">
                    {index + 1}.{' '}
                    <a
                      role="link"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={document.link}
                    >
                      {document.title}
                    </a>
                  </h4>
                </form>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
