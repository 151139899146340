import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

export const FacilityServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="services-main-div">
      <div className="services-patient-top-div">
        <h1>FACILITIES</h1>
      </div>
      <div className="services-lists login-header home-top-section home-top-content">
        <div className="home-paragraphs">
          <h2 className="make-me-blue">
            Consulting Services for Nursing Facilities:
          </h2>
          <ul className="lists">
            <li>
              Seasoned Consultant Pharmacists many of whom are Board Certified
              in Geriatrics
            </li>
            <li>Medication Regimen Review (MRR)</li>
            <li>Intermediate Medication Regimen Reviews (iMRR)</li>
            <li>Customizable Facility-Specific Reporting</li>
            <li>Gradual Dosage Reduction (GDR) reports</li>
            <li>Detailed Quality Assurance Reports (QA/QAPI)</li>
            <li>
              Implementation of Cost Saving Initiatives designed to save dollars
            </li>
            <li>Policy development</li>
          </ul>
          <p>
            A differentiating factor in utilizing Resident First is our
            Regulatory Expertise. We have an extensive partnership with Vaughan
            Consulting LLC featuring Bill Vaughan RN BSN, a nationally
            recognized expert in Long-Term care. This allows us to assist you in
            your regulatory compliance by providing:
          </p>
          <ul className="lists make-me-blue">
            <li>Regulatory in-servicing</li>
            <li>Survey preparation</li>
            <li>Mock surveys</li>
            <li>Real-time survey consultation</li>
            <li>2567 Review</li>
            <li>Plan of correction writing</li>
            <li>IDR (Informal Dispute Resolution) consultation</li>
          </ul>
        </div>
        <button className="btn btn-default contact-button">
          <Link className="make-text-white" to="/contact">
            Contact
          </Link>
        </button>
      </div>
    </div>
  );
};
