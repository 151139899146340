import axios from 'axios';

const SHOW_SINGLE_USER = 'SHOW_SINGLE_USER';
const ADD_FACILITY_USER = 'ADD_FACILITY_USER';

const showSingleUser = (singleUser) => {
  return {
    type: SHOW_SINGLE_USER,
    singleUser: singleUser,
  };
};

const addFacilityUser = (facilityUser) => {
  return {
    type: ADD_FACILITY_USER,
    facilityUser: facilityUser,
  };
};

export const fetchSingleUser = (id) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/api/users/${id}`);
      const foundUser = response.data;
      dispatch(showSingleUser(foundUser));
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchfacilityUser = (userId, facilityId) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`/api/users/${userId}`, {
        facilityId: facilityId,
      });
      const foundUser = response.data;
      dispatch(addFacilityUser(foundUser));
    } catch (error) {
      console.log(error);
    }
  };
};

// REDUCER
export default function singleUserReducer(state = [], action) {
  switch (action.type) {
    case SHOW_SINGLE_USER:
      return action.singleUser;
    default:
      return state;
  }
}
