import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

export const PharmacyServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="services-main-div">
      <div className="services-top-div">
        <h1>PHARMACIES</h1>
      </div>
      <div className="services-lists login-header home-top-section home-top-content">
        <div className="home-paragraphs">
          <h3 className="make-me-blue mb-medium">
            Our pharmacies can attest that we integrate seamlessly into your
            customer facing teams. Our keys are communication, customization and
            participation. The better we service your facilities, the less time
            and resources you have to spend on an area that does not generate
            revenue. Resident First does this in several ways:
          </h3>
          <h4>We help your bottom line by:</h4>
          <ul className="lists">
            <li>
              Eliminating the cost of an employee (both salary/wages and
              benefits)
            </li>
            <ul>
              <li>
                Average cost of a Pharmacist in the US is 147.086 annually *
              </li>
              <li>Average cost of an employee is between 29.5 – 30.0 % **</li>
              <li>
                Savings on elimination of software, hardware &amp; management
                costs
              </li>
            </ul>
            <li>Promulgation of YOUR Therapeutic Interchanges</li>
            <li>
              Reduce customer loss by tailoring services/reporting to their
              needs
            </li>
            <li>
              Provision of on-line access to facility reports for authorized
              pharmacy personnel
            </li>
          </ul>
          <h4>Maintaining your Customer Base by:</h4>
          <ul className="lists">
            <li>
              Customizing services to your customers like you would do yourself
            </li>
            <li>
              Customized reports that your Directors of Nursing want and utilize
            </li>
            <li>
              Creation of a monthly resident-specific, drug specific GDR report
              that reduces survey questions/issues
            </li>
            <li>
              Promulgation of Rx Therapeutic Interchange initiatives thus
              lowering facility Med-A and Managed-Care out-of-pocket expenses
            </li>
            <li>
              Promoting preventative therapeutic guidelines to reduce mortality
              &amp; morbidity allowing facilities to keep their residents
              in-house longer
            </li>
            <li>
              Offering Expert Regulatory support before, during and after annual
              survey reducing risk of citations and assessments of civil
              monetary penalties (CMPs)
            </li>
            <li>
              Provision of 24/7 online access to Consultant pharmacist
              Recommendations and Reports by authorized facility nursing staff
              and corporate staff for improved oversight and accountability
            </li>
            <li>
              Providing facilities with Clinical (non-TI) cost saving
              initiatives and Top 25 drug spend reports for inclusion into
              Quarterly Q/A reports
            </li>
          </ul>
        </div>
        <div>
          <button className="btn btn-default contact-button mb-medium">
            <Link className="make-text-white" to="/contact">
              Contact
            </Link>
          </button>
          <p>
            * salary.com/research/salary/benchmark/pharmacist-salary accessed
            3-21-23
          </p>
          <p>
            ** US Department of Labor Bureau of Labor Statistics (USDL -23-0488)
            March 17, 2023
          </p>
        </div>
      </div>
    </div>
  );
};
