import axios from 'axios';

const SHOW_DOCUMENTS = 'SHOW_DOCUMENTS';
const DELETE_DOCUMENTS = 'DELETE_DOCUMENTS';

const showDocuments = (documents) => {
  return {
    type: SHOW_DOCUMENTS,
    allDocuments: documents,
  };
};

const deleteDocument = (document) => {
  return {
    type: DELETE_DOCUMENTS,
    documentToRemove: document,
  };
};

// THUNK
export const fetchDocuments = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get('/api/regulatory');
      const documents = response.data;
      dispatch(showDocuments(documents));
    } catch (error) {
      console.log('Sorry not able to fetch documents', error);
    }
  };
};

export const addDocument = (data) => async (dispatch) => {
  try {
    const { title, link } = data;
    const category = 'regulatory';
    const res = await axios.post('/api/regulatory', {
      category,
      title,
      link,
    });
  } catch (error) {
    return error;
  }
};

export const deleteTheDocument = (id) => {
  return async (dispatch) => {
    try {
      const response = await axios.delete(`/api/regulatory/${id}`);
      const documentRemoved = response.data;

      dispatch(deleteDocument(documentRemoved));
    } catch (error) {
      console.log('Sorry not able to delete this document', error);
    }
  };
};

// REDUCER
export default function allDocumentsReducer(state = [], action) {
  switch (action.type) {
    case SHOW_DOCUMENTS:
      return action.allDocuments;
    case DELETE_DOCUMENTS:
      return state.filter(
        (document) => document.id !== action.documentToRemove.id
      );
    default:
      return state;
  }
}
