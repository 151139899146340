import axios from 'axios';

const SHOW_SINGLE_FACILITY = 'SHOW_SINGLE_FACILITY';

const showSingleFacility = (singleFacility) => {
  return {
    type: SHOW_SINGLE_FACILITY,
    singleFacility: singleFacility,
  };
};

export const fetchSingleFacility = (id) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/api/facilities/${id}`);
      const foundFacility = response.data;

      dispatch(showSingleFacility(foundFacility));
    } catch (error) {
      console.log(error);
    }
  };
};

// REDUCER
export default function singleFacilityReducer(state = [], action) {
  switch (action.type) {
    case SHOW_SINGLE_FACILITY:
      return action.singleFacility;
    default:
      return state;
  }
}
