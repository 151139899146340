import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import Home from './components/Home';
import { me } from './store';
import { Contact } from './components/Contact';
import { Careers } from './components/Careers';
import { Admin } from './components/Admin';
import { About } from './components/About';
import { Portal } from './components/Portal';
import { Antibiotic } from './components/Antibiotic';
import { ClinicalResources } from './components/ClinicalResources';
import { FormsResources } from './components/FormsResources';
import { Login } from './components/Login';
import { AllUsers } from './components/AllUsers';
import { AllFacilities } from './components/AllFacilities';
import { SingleUser } from './components/SingleUser';
import { SingleFacility } from './components/SingleFacility';
import { SinglePortal } from './components/SinglePortal';
import { Regulatory } from './components/Regulatory';
import { Account } from './components/Account';
import { FacilityServices } from './components/FacilityServices';
import { PharmacyServices } from './components/PharmacyServices';

/**
 * COMPONENT
 */
class Routes extends Component {
  componentDidMount() {
    this.props.loadInitialData();
  }

  render() {
    const { isLoggedIn, isAdmin } = this.props;

    return (
      <div>
        {isLoggedIn && isAdmin ? (
          <Switch>
            <Route path="/home" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/contact" component={Contact} />
            <Route path="/careers" component={Careers} />
            <Route exact path="/portal" component={Portal} />
            <Route path="/portal/:id" component={SinglePortal} />
            <Route path="/antibiotic-stewardship" component={Antibiotic} />
            <Route path="/clinical-resources" component={ClinicalResources} />
            <Route path="/regulatory" component={Regulatory} />
            <Route path="/forms-resources" component={FormsResources} />
            <Route exact path="/admin" component={Admin} />
            <Route exact path="/users" component={AllUsers} />
            <Route path="/users/:id" component={SingleUser} />
            <Route path="/facilities/:id" component={SingleFacility} />
            <Route path="/facilities" component={AllFacilities} />
            <Route path="/account" component={Account} />
            <Route path="/pharmacy-services" component={PharmacyServices} />
            <Route path="/facility-services" component={FacilityServices} />
            <Redirect to="/portal" />
          </Switch>
        ) : isLoggedIn ? (
          <Switch>
            <Route path="/home" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/contact" component={Contact} />
            <Route path="/careers" component={Careers} />
            <Route exact path="/portal" component={Portal} />
            <Route path="/portal/:id" component={SinglePortal} />
            <Route path="/antibiotic-stewardship" component={Antibiotic} />
            <Route path="/clinical-resources" component={ClinicalResources} />
            <Route path="/regulatory" component={Regulatory} />
            <Route path="/forms-resources" component={FormsResources} />
            <Route path="/account" component={Account} />
            <Route path="/pharmacy-services" component={PharmacyServices} />
            <Route path="/facility-services" component={FacilityServices} />
            <Redirect to="/portal" />
          </Switch>
        ) : (
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/home" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/contact" component={Contact} />
            <Route path="/careers" component={Careers} />
            <Route path="/antibiotic-stewardship" component={Antibiotic} />
            <Route path="/clinical-resources" component={ClinicalResources} />
            <Route path="/regulatory" component={Regulatory} />
            <Route path="/forms-resources" component={FormsResources} />
            <Route path="/login" component={Login} />
            <Route path="/pharmacy-services" component={PharmacyServices} />
            <Route path="/facility-services" component={FacilityServices} />
            <Redirect to="/home" />
          </Switch>
        )}
      </div>
    );
  }
}

/**
 * CONTAINER
 */
const mapState = (state) => {
  return {
    // Being 'logged in' for our purposes will be defined has having a state.auth that has a truthy id.
    // Otherwise, state.auth will be an empty object, and state.auth.id will be falsey
    isLoggedIn: !!state.auth.id,
    isAdmin: state.auth.isAdmin,
  };
};

const mapDispatch = (dispatch) => {
  return {
    loadInitialData() {
      dispatch(me());
    },
  };
};

// The `withRouter` wrapper makes sure that updates are not blocked
// when the url changes
export default withRouter(connect(mapState, mapDispatch)(Routes));
