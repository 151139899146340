import Navbar from './components/Navbar';
import Routes from './Routes';
import Footer from './components/Footer';
import { Popup } from './components/Popup';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from './store';

const App = () => {
  const [buttonPopup, setButtonPopup] = useState(false);
  const timeoutClockRef = useRef(false); // Ref to track if user should be logged out
  const dispatch = useDispatch();
  const logoutTimerRef = useRef(null); // Ref to store the logout timer

  useEffect(() => {
    // Start the initial popup timer
    const initialTimer = setTimeout(() => {
      setButtonPopup(true);
      timeoutClockRef.current = true; // Set ref to true to track if user is inactive
      stillHereCheck();
    }, 1200000);

    return () => {
      clearTimeout(initialTimer); // Clear the timer if the component unmounts
    };
  }, []);

  const stillHereCheck = () => {
    logoutTimerRef.current = setTimeout(() => {
      if (timeoutClockRef.current) {
        // Check if the user didn't click anything
        setButtonPopup(false);
        dispatch(logout());
      }
    }, 60000);
  };

  const handleLogoutClick = () => {
    clearTimeout(logoutTimerRef.current); // Stop any ongoing logout timers
    setButtonPopup(false);
    dispatch(logout());
  };

  const handleContinueClick = () => {
    clearTimeout(logoutTimerRef.current); // Clear the logout timer to prevent logout
    setButtonPopup(false);
    timeoutClockRef.current = false; // Reset clock immediately to prevent logout

    // Restart the popup timer
    const restartTimer = setTimeout(() => {
      setButtonPopup(true);
      timeoutClockRef.current = true; // Set clock to true again for the next cycle
      stillHereCheck(); // Check again
    }, 1200000);

    return () => {
      clearTimeout(restartTimer); // Clear the restart timer if component unmounts
    };
  };

  return (
    <div>
      <Navbar />
      <Routes />
      <Popup trigger={buttonPopup}>
        <button
          className="btn btn-default popup-btn-continue"
          onClick={() => handleContinueClick()}
        >
          Continue session
        </button>
        <button
          className="btn btn-default popup-btn-logout"
          href="#"
          onClick={() => handleLogoutClick()}
        >
          Log out
        </button>
      </Popup>
      <Footer />
    </div>
  );
};

export default App;
