import { createStore, combineReducers, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import auth from './auth';
import users from './all-users';
import form from './forms';
import facilities from './all-facilities';
import showSpecificFacilities from './all-facilities';
import singleUser from './single-user';
import singleFacility from './single-facility';
import allDocuments from './antibiotic';
import showAllS3Documents from './single-portal';
import showUsersFacilities from './portal';

const reducer = combineReducers({
  auth,
  users,
  form,
  facilities,
  singleUser,
  singleFacility,
  allDocuments,
  showSpecificFacilities,
  showAllS3Documents,
  showUsersFacilities,
});
const middleware = composeWithDevTools(
  applyMiddleware(thunkMiddleware, createLogger({ collapsed: true }))
);
const store = createStore(reducer, middleware);

export default store;
export * from './auth';
