import React from 'react';
import { useEffect } from 'react';

export const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about-section">
      <div className="about-content">
        <h1 className="header-about about-page-header">OUR TEAM</h1>
        <hr className="header-line-about" />
        <div className="about-profile-section">
          <img src="/rob-headshot.png" className="about-headshots" />
          <h3 className="make-me-blue">
            Rob Shulman, RPh BCGP FASCP President
          </h3>
          <p>
            Rob Shulman RPh FASCP BCGP is a Board Certified Geriatric Pharmacist
            and a Fellow in the American Society of Consultant Pharmacists.
            Currently he is the owner of Resident First Consultants LLC, a
            pharmacy consulting service providing clinical pharmacy and
            medication management services to Long Term Care facilities,
            individuals in the community and medical professionals. He was
            formerly the National Director of Consulting Services for Remedi
            SeniorCare and prior was the Vice President of Clinical Services for
            Cornerstone Pharmacy, a company he helped form in late 2005. Rob has
            served the Long Term Care community for 25 years and has previously
            been an Executive Vice President for Omnicare Eastlake, an Executive
            Director of Medic Pharmacy Services and a Regional Executive
            Director for NCS HealthCare. He currently serves as the Past
            President for the Ohio Chapter of the American Society of Consultant
            Pharmacists (ASCP) and has been an active member for 20 years. Rob
            has completed the ASCP Pain Management Traineeship and is a member
            of the Cleveland Stroke Club. Rob graduated from the University of
            Toledo College of Pharmacy and frequently lectures and writes in the
            Long Term Care setting.
          </p>
          <p>
            View Rob's Linkedin Profile
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/in/rob-shulman-2282a7b2/"
            >
              {' '}
              here
            </a>
          </p>
          <button className="btn btn-default contact-button larger-button">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://drive.google.com/file/d/1P_SYx-nY0bbAAI71zw_8wKd7ZiXQVwEA/view?usp=sharing"
              className="make-text-white"
            >
              View Rob's Resume
            </a>
          </button>
        </div>
        <div className="about-profile-section">
          <img src="/bill-headshot.png" className="about-headshots" />
          <h3 className="make-me-blue">
            Bill Vaughan, RN, BSN, Regulatory Consultant
          </h3>
          <p>
            Bill Vaughan, RN is a consultant, partnering with Resident First
            Consultants to improve regulatory compliance, promote best practices
            and advance person-centered care. In his nearly 40 years as a
            registered nurse, Bill has held leadership positions including Chief
            Nurse at the Maryland State Survey Agency and Vice President of
            Education and Clinical Affairs at a LTC pharmacy providing services
            in over 30 states. He was a member of CMS expert panels that
            developed guidance to surveyors on pain and end of life care and
            recently worked as a CMS contractor on regulations related to QAPI.
            Over his career, he has given hundreds of local, regional and
            national presentations on the regulation of health care facilities
            and has taught pharmacy and nursing students in multiple states.
            Recently, Bill was a faculty member for the ECHO/IHI National
            Nursing Home COVID Action Network and an advisor to the Institute
            for Safe Medication Practices LTC newsletter.
          </p>
          <p>
            Bill is available to assist nursing home and assisted living
            providers with their educational needs as well as regulatory support
            including informal dispute resolution and plans of correction. He
            can be reached at vaughanrn58@gmail.com
          </p>
          <button className="btn btn-default contact-button larger-button">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://drive.google.com/file/d/1N6uOVXVdba-OE430yHAl9gp8WODfH5Qt/view"
              className="make-text-white"
            >
              View Bill's Resume
            </a>
          </button>
        </div>
        <div className="about-profile-section">
          <img src="/annette-headshot.png" className="about-headshots" />
          <h3 className="make-me-blue">
            Annette Elam, RPh, Lead Consultant Pharmacist
          </h3>
          <p>
            Formerly the Director of Consulting services for Pure Service and
            MedRx Pharmacies, Annette is a seasoned consultant pharmacist with
            over 23 years of experience in long term care. Annette is currently
            the lead consultant for Resident First Consultants and has also
            worked as a Consultant Pharmacist for HealthCare Pharmacy, Remedi
            SeniorCare and Unified Healthcare Consultants. She has been a member
            of the American Society of Consultant Pharmacists for over 20 years
            and is licensed in multiple states. Annette attened Ohio State
            University and graduated from the University of Cincinnati College
            of Pharmacy. Her wealth of experience and leadership skills make her
            a welcome addition to Resident First Consultants.
          </p>
          <button className="btn btn-default contact-button larger-button">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://drive.google.com/file/d/1wacQ_r3s3BydEXGdqGMKtQIJYR6hVGW6/view?usp=share_link"
              className="make-text-white"
            >
              View Annette's Resume
            </a>
          </button>
        </div>
      </div>
    </div>
  );
};
