import React, { useState, useEffect } from 'react';
import { fetchSingleUser, fetchfacilityUser } from '../store/single-user';
import { fetchFacilities } from '../store/all-facilities';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

export const SingleUser = () => {
  const error = useSelector((state) => state.auth.error);
  const dispatch = useDispatch();
  const singleUser = useSelector((state) => state.singleUser);
  const allTheFacilities = useSelector((state) => state.facilities);
  const [formStatus, setFormStatus] = useState(false);

  const thisURL = window.location.href;
  const userIdArr = thisURL.split('/');
  const userIdFromUrl = userIdArr.pop();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchSingleUser(userIdFromUrl));
    dispatch(fetchFacilities());
  }, []);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const select = evt.target.select;
    const facilityId = select.value.split('.')[0];
    if (select.value && select.value != 'select') {
      dispatch(fetchfacilityUser(userIdFromUrl, facilityId));
      select.value = 'select';
      setFormStatus(true);
      setTimeout(() => {
        setFormStatus(false);
      }, 3000);
    }
  };

  return (
    <div className="admin-users-div">
      <div className="back-to-dashboard-div2">
        <Link to="/users" className="back-to-dashboard">
          {'⬅ back to all users'}
        </Link>
      </div>
      <div>
        {singleUser.map((user) => {
          return (
            <div key={user.id}>
              <form>
                <h1 className="header-admin">{user.username}</h1>
                {user.isConsultant ? (
                  <div className="admin-usertype-consultant">CONSULTANT</div>
                ) : user.isNurse ? (
                  <div className="admin-usertype-nurse">NURSE</div>
                ) : (
                  <div className="admin-usertype-admin">*ADMIN*</div>
                )}
              </form>
              <div>
                <form onSubmit={handleSubmit}>
                  <h4>Assign to new facility</h4>
                  <select name="select">
                    <option>select</option>
                    {allTheFacilities.map((facility) => {
                      return (
                        <option key={facility.id}>
                          {facility.id}. {facility.name}
                        </option>
                      );
                    })}
                  </select>
                  <button>Assign</button>
                  {error && error.response && (
                    <div> {error.response.data} </div>
                  )}
                </form>
                <div className="success-form-center">
                  {formStatus ? (
                    <div class="alert alert-success alert-pop-up" role="alert">
                      <strong>Success!</strong>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
