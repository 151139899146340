import axios from 'axios';

const SET_FORMS = 'SET FORMS';

const setForms = (form) => {
  return {
    type: SET_FORMS,
    form,
  };
};

export const fetchForms = () => async (dispatch) => {
  try {
    const { data } = await axios.post('/api/forms');

    dispatch(setForms(data));
  } catch (error) {
    console.log(error);
  }
};

export default function (state = {}, action) {
  switch (action.type) {
    case SET_FORMS:
      return action.form;
    default:
      return state;
  }
}
