import {
  S3Client,
  PutObjectCommand,
  ListObjectsCommand,
  GetObjectCommand,
  DeleteObjectCommand,
} from '@aws-sdk/client-s3';
import { saveAs } from 'file-saver';

const s3Client = new S3Client({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  },
});

const bucketParams = { Bucket: process.env.REACT_APP_AWS_BUCKET };
const SHOW_ALL_S3_DOCUMENTS = 'SHOW_ALL_DOCUMENTS';

const showAllS3Documents = (allS3Documents) => {
  if (allS3Documents) {
    return {
      type: SHOW_ALL_S3_DOCUMENTS,
      allS3Documents: allS3Documents,
    };
  } else {
    return {
      type: SHOW_ALL_S3_DOCUMENTS,
      allS3Documents: [],
    };
  }
};

export const sendDocument = (document) => {
  return async (dispatch) => {
    const { title, file, facility, progress, success } = document;

    const cbzFile = 'Consultant Pharmacist - (.cbz)';
    let uploadParams = {};

    if (facility === cbzFile) {
      // const cbzToBinaryFile = new Blob([file]);
      // try 'multipart/form-data' next for content type
      uploadParams = {
        Bucket: process.env.REACT_APP_AWS_BUCKET,
        Key: `${facility}/${title}`,
        Body: file,
        ContentType: 'application/x-cbz',
      };
    } else {
      uploadParams = {
        Bucket: process.env.REACT_APP_AWS_BUCKET,
        Key: `${facility}/${title}`,
        Body: file,
        ContentType: file.type,
      };
    }

    try {
      await s3Client.send(new PutObjectCommand(uploadParams));
      progress.style.visibility = 'hidden';
      success.style.visibility = 'visible';
      setTimeout(() => {
        success.style.visibility = 'hidden';
      }, 3500);
    } catch (err) {
      alert('Error uploading file.');
      console.log(err);
    }
  };
};

export const fetchS3Documents = (facility) => {
  return async (dispatch) => {
    try {
      if (!facility || facility.length === 0) {
        return; // Exit early if facility is undefined or empty
      }

      const keyName = await facility[0].name;
      bucketParams['Prefix'] = keyName;
      const { Contents } = await s3Client.send(
        new ListObjectsCommand(bucketParams)
      );

      return dispatch(showAllS3Documents(Contents));
    } catch (err) {
      console.log('Error', err);
    }
  };
};

// export const getSpecificDocument = (key) => {
//   return async (dispatch) => {
//     const bucketParams = {
//       Bucket: process.env.REACT_APP_AWS_BUCKET,
//       Key: key,
//     };

//     try {
//       const data = await s3Client.send(new GetObjectCommand(bucketParams));
//       const datKey = 'Consultant Pharmacist - (.dat)';
//       const cbzKey = 'Consultant Pharmacist - (.cbz)';
//       const downloadName = key.split('/');

//       if (key.includes(datKey)) {
//         const datFileContents = await data.Body.transformToString();
//         const datNewBlob = new Blob([datFileContents]);
//         saveAs(datNewBlob, `${downloadName[1]}`);
//       } else if (key.includes(cbzKey)) {
//         const cbzFileContents = await data.Body.transformToByteArray();
//         const cbzNewBlob = new Blob([cbzFileContents]);
//         saveAs(cbzNewBlob, `${downloadName[1]}`);
//       } else {
//         const contents = await data.Body.transformToByteArray();
//         const newBlob = new Blob([contents]);
//         saveAs(newBlob, `${downloadName[1]}`);
//       }
//     } catch (err) {
//       alert('Error fetching file.');
//       console.log(err);
//     }
//   };
// };

// GPT version below:
export const getSpecificDocument = (documentKey) => {
  return async (dispatch) => {
    const { key, progress, success } = documentKey;

    const bucketParams = {
      Bucket: process.env.REACT_APP_AWS_BUCKET,
      Key: key,
    };

    try {
      const data = await s3Client.send(new GetObjectCommand(bucketParams));
      const downloadName = key.split('/').pop();

      let fileContents, mimeType;
      console.log('hit5', key, key.endsWith('.cbz'));
      // Determine the MIME type and file contents based on the file extension
      if (key.endsWith('.dat')) {
        fileContents = await data.Body.transformToByteArray();
        mimeType = 'application/octet-stream';
      } else if (key.endsWith('.cbz')) {
        fileContents = await data.Body.transformToByteArray();
        console.log('hit6', fileContents);
        mimeType = 'application/x-cbz';
      } else {
        fileContents = await data.Body.transformToByteArray();
        mimeType = 'application/octet-stream'; // Default for other types
      }

      // Create a Blob from the file contents
      const newBlob = new Blob([fileContents], { type: mimeType });
      console.log('hit7');
      // Use file-saver to save the file with the correct name
      saveAs(newBlob, downloadName);
      progress.style.visibility = 'hidden';
      success.style.visibility = 'visible';
      setTimeout(() => {
        success.style.visibility = 'hidden';
      }, 3500);
    } catch (err) {
      alert('Error fetching file.');
      console.error(err);
    }
  };
};

export const deleteSpecificDocument = async (key) => {
  bucketParams['Key'] = key;
  try {
    await s3Client.send(new DeleteObjectCommand(bucketParams));
  } catch (error) {
    console.log(error);
  }
};

// REDUCER
export default function allS3DocumentsReducer(state = [], action) {
  switch (action.type) {
    case SHOW_ALL_S3_DOCUMENTS:
      return action.allS3Documents;
    default:
      return state;
  }
}
