import axios from 'axios';

const SHOW_USERS = 'SHOW_USERS';
const DELETE_USER = 'DELETE_USER';
const UPDATE_USER = 'UPDATE_USER';
const SHOW_SPECIFIC_USERS = 'SHOW_SPECIFIC_USERS';

const showUsers = (users) => {
  return {
    type: SHOW_USERS,
    allUsers: users,
  };
};

const showSpecificUsers = (specificUsers) => {
  return {
    type: SHOW_SPECIFIC_USERS,
    specificUsers: specificUsers,
  };
};

const deleteUser = (user) => {
  return {
    type: DELETE_USER,
    userToRemove: user,
  };
};

const updateUser = (user) => {
  return {
    type: UPDATE_USER,
    userToUpdate: user,
  };
};

// THUNK
export const fetchUsers = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get('/api/users');
      const users = response.data;
      dispatch(showUsers(users));
    } catch (error) {
      console.log('Sorry not able to fetch Users', error);
    }
  };
};

export const fetchUserPassword = (email) => {
  try {
    const res = axios.post('/api/users/forgot', {
      email,
    });
  } catch (error) {
    console.log(error);
  }
};

export const addUser =
  (username, email, isConsultant, isNurse) => async (dispatch) => {
    try {
      const res = await axios.post('/api/users', {
        username,
        email,
        isConsultant,
        isNurse,
      });
    } catch (error) {
      console.log(error);
    }
  };

export const fetchSpecificUsers = (id) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/api/facilities/${id}/users`);
      const specificUsers = response.data;
      console.log('THUNK HEREE', specificUsers);

      dispatch(showSpecificUsers(specificUsers));
    } catch (error) {
      console.log('Sorry not able to fetch users', error);
    }
  };
};

export const deleteTheUser = (id) => {
  return async (dispatch) => {
    try {
      const response = await axios.delete(`/api/users/${id}`);
      const userRemoved = response.data;

      dispatch(deleteUser(userRemoved));
    } catch (error) {
      console.log('Sorry not able to delete this User', error);
    }
  };
};

export const updateTheUser = (updatedUserData) => {
  return async (dispatch) => {
    try {
      const res = await axios.put('/api/users', updatedUserData);
      dispatch(updateUser(res.data));
    } catch (error) {
      console.log('Sorry not able to update user', error);
    }
  };
};

// REDUCER
export default function allUserReducer(state = [], action) {
  switch (action.type) {
    case SHOW_USERS:
      return action.allUsers;
    case SHOW_SPECIFIC_USERS:
      console.log('REDUCER', action);
      return action.specificUsers;
    case DELETE_USER:
      return state.filter((user) => user.id !== action.userToRemove.id);
    case UPDATE_USER:
      return action.user;
    default:
      return state;
  }
}
