import React, { useEffect } from 'react';
import { fetchFacilities } from '../store/all-facilities';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchUsersFacilities } from '../store/portal';

export const Portal = () => {
  const allTheFacilities = useSelector((state) => state.facilities);
  const mainUser = useSelector((state) => state.auth);
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.auth.id);
  const specificFacilities = useSelector((state) => state.showUsersFacilities);
  const isNurse = useSelector((state) => state.auth.isNurse);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchUsersFacilities(userID));
    dispatch(fetchFacilities());
  }, []);

  return (
    <div className="portal-main-div wallpaper">
      <div className="portal-sub-div">
        <h1 className="make-me-blue">Welcome, {mainUser.username}</h1>
        <div className="portal-container-div main-table-div-longer">
          {isAdmin ? (
            <div className="container-fluid">
              {allTheFacilities.map((facility) => {
                return (
                  <div key={facility.id} className="row portal-border">
                    <div className="col-md-8 portal-row">{facility.name}</div>
                    <div className="col-md-4 portal-row">
                      <Link to={`/portal/${facility.id}`}>
                        View/Upload Documents
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : specificFacilities.length > 0 ? (
            <div className="container-fluid">
              {specificFacilities.map((facility) => {
                return (
                  <div key={facility.id} className="row portal-border">
                    <div className="col-md-8 portal-row">{facility.name}</div>
                    <div className="col-md-4 portal-row">
                      {isNurse === true ? (
                        <Link to={`/portal/${facility.id}`}>
                          View Documents
                        </Link>
                      ) : (
                        <Link to={`/portal/${facility.id}`}>
                          View/Upload Documents
                        </Link>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div>
              <p>
                You have not been assigned access to any facilities. Please
                contact a Resident First Administrator for facility access or
                submit a request through our contact button below.
              </p>
              <Link to="/contact">
                <button className="btn btn-default contact-button">
                  Contact
                </button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
