import axios from 'axios';

const UPDATE_USER = 'UPDATE_USER';

const accountUpdateUser = (user) => {
  return {
    type: UPDATE_USER,
    updatedUser: user,
  };
};

// THUNK
export const updateTheAccountUser = (updatedUserData) => {
  return async (dispatch) => {
    try {
      const res = await axios.put('/api/account', updatedUserData);
      dispatch(accountUpdateUser(res.data));
    } catch (error) {
      console.log('Sorry not able to update user', error);
    }
  };
};

// REDUCER
export default function allUserReducer(state = [], action) {
  switch (action.type) {
    case UPDATE_USER:
      return action.user;
    default:
      return state;
  }
}
