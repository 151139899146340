import React, { useEffect } from 'react';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-google';

export const Careers = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    loadReCaptcha();
  }, []);

  const handleSubmit = (evt) => {
    evt.preventDefault();

    let name = evt.target.name;
    let email = evt.target.email;
    let phoneNumber = evt.target.phone;
    let licenseNumber = evt.target.licenseNumber;
    let statesLicensed = evt.target.statesLicensed;
    let stateResiding = evt.target.stateResiding;
    let countyResiding = evt.target.countyResiding;
    let timesAvailable = evt.target.times;

    let formData = {
      name: name.value,
      email: email.value,
      phoneNumber: phoneNumber.value,
      licenseNumber: licenseNumber.value,
      statesLicensed: statesLicensed.value,
      stateResiding: stateResiding.value,
      countyResiding: countyResiding.value,
      timesAvailable: timesAvailable.value,
    };

    let isChecked = false;

    const recaptcha = $('#g-recaptcha-response').val();
    if (recaptcha === '') {
      alert('Please check the recaptcha');
      return;
    } else {
      isChecked = true;
    }

    if (
      isChecked &&
      name &&
      email &&
      phoneNumber &&
      licenseNumber &&
      statesLicensed &&
      stateResiding &&
      countyResiding &&
      timesAvailable
    ) {
      let xhr = new XMLHttpRequest();
      xhr.open('POST', '/forms', true);
      xhr.setRequestHeader('content-type', 'application/json');
      xhr.onload = function () {
        console.log('BLACK', xhr.responseText);
        if (xhr.responseText === 'success') {
          // URL.revokeObjectURL(hrefLink); // clears it
          window.location.reload();
          alert('Email sent successfully!');
        } else {
          alert('Please check that all fields are filled out');
        }
      };
      xhr.send(JSON.stringify(formData));
    } else {
      alert('Please check that all fields are correct');
    }
  };

  return (
    <div className="login-header">
      <div className="header-main contact-form">
        <h1>CAREERS AT RESIDENT FIRST CONSULTANTS</h1>
        <hr className="header-line-careers" />
        <div lassName="contact-form">
          <h3>Career Application</h3>
          <form onSubmit={handleSubmit}>
            <label>Full name</label>
            <input type="text" name="name" className="form-items" />
            <label>Email address</label>
            <input type="text" name="email" className="form-items" />
            <label>Phone Number</label>
            <input type="text" name="phone" className="form-items" />
            <label>Pharmacist license number (include state)</label>
            <input type="text" name="licenseNumber" className="form-items" />
            <label>Other states licensed in</label>
            <input type="text" name="statesLicensed" className="form-items" />
            <label>State in which you reside</label>
            <select name="stateResiding" className="form-items">
              <option value="--" className="select-text">
                Select
              </option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </select>
            <label>County in which you reside</label>
            <input type="text" name="countyResiding" className="form-items" />
            <label>
              Good time to contact you (please provide multiple times)
            </label>
            <input type="text" name="times" className="form-items" />
            <label>Check the box before submitting</label>
            <ReCaptcha
              size="visible"
              render="explicit"
              sitekey={process.env.REACT_APP_CAPTCHA_KEY}
            />
            <button className="btn btn-default contact-button">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};
