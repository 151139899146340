import React, { useState, useEffect, useRef } from 'react';
import { fetchSingleFacility } from '../store/single-facility';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  sendDocument,
  fetchS3Documents,
  getSpecificDocument,
  deleteSpecificDocument,
} from '../store/single-portal';

export const SinglePortal = () => {
  const singleFacility = useSelector((state) => state.singleFacility);
  const dispatch = useDispatch();
  const thisURL = window.location.href;
  const facilityIdArr = thisURL.split('/');
  const facilityIdFromUrl = facilityIdArr.pop();
  const allS3Documents = useSelector((state) => state.showAllS3Documents);
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const isNurse = useSelector((state) => state.auth.isNurse);
  const [deleteMe, setDeleteMe] = useState('false');
  const [docKey, setDocKey] = useState('');
  const [documentTitle, setDocumentTitle] = useState('');
  const [docRender, setDocRender] = useState(false);

  const uploadFile = async (evt) => {
    evt.preventDefault();
    const title = evt.target.title;
    const file = evt.target.file;
    const getFacilityName = singleFacility[0].name;
    const fileUpload = document.getElementById('fileUpload');
    let formData = new FormData();
    formData.append('file', fileUpload.files[0]);

    const progressBar = document.getElementById('progressBar');
    const successBar = document.getElementById('successBar');

    if (title.value && file.value) {
      progressBar.style.visibility = 'visible';
      await dispatch(
        sendDocument({
          title: title.value,
          file: formData,
          facility: getFacilityName,
          progress: progressBar,
          success: successBar,
        })
      );
      setDocumentTitle('');
      file.value = '';
      setDocRender(!docRender);
    } else {
      alert('Please fill out all fields');
    }
  };

  const deleteSpecificDoc = async (key) => {
    await deleteSpecificDocument(key);
    setDocRender(!docRender);
    setDeleteMe(false);
  };

  const downloadSpecificDocument = async (key) => {
    const progressBar = document.getElementById('progressBar');
    const successBar = document.getElementById('successBar');

    progressBar.style.visibility = 'visible';
    await dispatch(
      getSpecificDocument({
        key,
        progress: progressBar,
        success: successBar,
      })
    );
  };

  const prevSingleFacilityRef = useRef(null);
  const prevAllS3DocumentsRef = useRef(null);
  const dispatchRef = useRef(null);
  const isDispatchedRef = useRef(false);
  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    // Ensure dispatch function is initialized
    if (!dispatchRef.current) {
      dispatchRef.current = dispatch;
    }

    // Check if the action has already been dispatched in the current render cycle
    if (!isDispatchedRef.current) {
      // Dispatch fetchS3Documents only if singleFacility has changed or allS3Documents is different from the previous value
      if (
        singleFacility !== prevSingleFacilityRef.current ||
        allS3Documents !== prevAllS3DocumentsRef.current
      ) {
        dispatchRef.current(fetchS3Documents(singleFacility));
        prevSingleFacilityRef.current = singleFacility;
        prevAllS3DocumentsRef.current = allS3Documents;
      }
      // Set the flag to true to indicate that the action has been dispatched
      isDispatchedRef.current = true;
    } else {
      // Reset the flag for the next render cycle
      isDispatchedRef.current = false;
    }
  }, [singleFacility, allS3Documents]);

  // Other useEffects remain unchanged
  useEffect(() => {
    // Dispatch fetchS3Documents action when the component mounts
    dispatch(fetchS3Documents(singleFacility)).catch((error) => {
      // If dispatch fails, retry up to 3 times with a delay
      if (retryCount < 3) {
        setTimeout(() => {
          setRetryCount(retryCount + 1);
        }, 1000); // Retry after 1 second
      } else {
        console.error('Failed to fetch S3 documents:', error);
      }
    });
  }, [retryCount]); // Retry whenever retryCount changes

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchSingleFacility(facilityIdFromUrl));
  }, []);

  useEffect(() => {
    dispatch(fetchS3Documents(singleFacility));
  }, [docRender]);

  return (
    <div className="portal-single-div wallpaper">
      <div className="back-to-dashboard-div">
        <Link to="/portal" className="back-to-dashboard">
          {'⬅ back to portal'}
        </Link>
      </div>
      <div className="portal-sub-div">
        {singleFacility.map((facility) => {
          return (
            <div key={facility.id}>
              <h1 className="header-admin">{facility.name}</h1>
            </div>
          );
        })}
        {isNurse === true ? (
          ''
        ) : (
          <div>
            <h4 className="portal-form-div">Upload Document:</h4>
            <form
              onSubmit={uploadFile}
              className="upload"
              method="post"
              encType="multipart/form-data"
            >
              <label className="portal-file-title">File Name: </label>
              <input
                className="portal-input"
                type="text"
                name="title"
                value={documentTitle}
                onChange={(e) => setDocumentTitle(e.target.value)}
              />
              <input
                id="fileUpload"
                type="file"
                name="file"
                className="portal-file-upload portal-form-div"
                onChange={(e) =>
                  setDocumentTitle(e.target.value.split(/(\\|\/)/g).pop())
                }
              />
              <button className="btn btn-default contact-button">Submit</button>
              <br></br>
              <img
                id="progressBar"
                style={{ visibility: 'hidden' }}
                src="/newLoadingBar.gif"
              />
              <div id="successBar" style={{ visibility: 'hidden' }}>
                <p className="successText">Success</p>
                <img className="successCheck" src="/successCheck.gif" />
              </div>
            </form>
          </div>
        )}
        <div className="portal-table-main">
          <h4 className="portal-form-div">Facility Documents:</h4>
          <div className="main-table-div portal-padding-bottom">
            <table className="table table-hover portal-table">
              <thead>
                <tr className="table-info table-items">
                  <th scope="col">#</th>
                  <th scope="col">File</th>
                  <th scope="col">File Name</th>
                  <th scope="col">Last Modified</th>
                  {isAdmin ? <th scope="col"></th> : ''}
                  {deleteMe === true ? <th scope="col">Confirm Delete</th> : ''}
                  {deleteMe === true ? <th scope="col"></th> : ''}
                </tr>
              </thead>
              <tbody>
                {allS3Documents.map((document, index) => {
                  const unformattedDate = JSON.stringify(document.LastModified)
                    .split('T')[0]
                    .substring(1);

                  const [year, month, day] = unformattedDate.split('-');
                  const date = `${month}-${day}-${year}`;

                  const name = document.Key.split('/')[1];

                  return (
                    <tr key={index} className="table-items">
                      <td scope="row">{index + 1}</td>
                      <td>
                        <a
                          id="downloadButton"
                          onClick={() => downloadSpecificDocument(document.Key)}
                          className="portal-table-download"
                        >
                          <span class="material-symbols-outlined">
                            description
                          </span>
                        </a>
                      </td>
                      <td>{name}</td>
                      <td>{date}</td>
                      {isAdmin ? (
                        <td>
                          <a
                            className="pointer-me portal-table-download"
                            onClick={() => {
                              setDeleteMe(true);
                              setDocKey(document.Key);
                            }}
                          >
                            <span class="material-symbols-outlined">
                              delete
                            </span>
                          </a>
                        </td>
                      ) : (
                        ''
                      )}
                      {deleteMe === true && docKey === document.Key ? (
                        <td>
                          <a
                            className="pointer-me portal-table-download"
                            onClick={() => deleteSpecificDoc(document.Key)}
                          >
                            confirm
                          </a>
                        </td>
                      ) : (
                        ''
                      )}
                      {deleteMe === true && docKey === document.Key ? (
                        <td>
                          <a
                            className="pointer-me portal-table-download"
                            onClick={() => {
                              setDeleteMe(false);
                            }}
                          >
                            cancel
                          </a>
                        </td>
                      ) : (
                        ''
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
