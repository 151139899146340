import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => (
  <div className="footer-all">
    <div className="footer-main">
      <div className="footer-col">
        <h2>Connect with us</h2>
        <div className="social-icons-div">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/in/rob-shulman-2282a7b2/"
          >
            <img className="social-icon" src="./linkedin-icon.png" />
          </a>
        </div>
      </div>
      <div className="footer-col">
        <h2>Resources</h2>
        <div className="footer-links-div">
          <Link className="resources-footer-links" to="/clinical-resources">
            Clinical Resources
          </Link>
          <Link className="resources-footer-links" to="/regulatory">
            Regulatory Resources
          </Link>
          <Link className="resources-footer-links" to="/forms-resources">
            Forms
          </Link>
        </div>
      </div>
      <div className="footer-col">
        <h2>Contact</h2>
        <p className="footer-contact-links">rob@residentfirstconsultants.com</p>
      </div>
    </div>
    <div className="bottom-footer">
      <p>© 2023 Resident First Consultants</p>
    </div>
  </div>
);

export default Footer;
