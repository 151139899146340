import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authenticate } from '../store';
import { fetchUserPassword } from '../store/all-users';

export const Login = () => {
  const error = useSelector((state) => state.auth.error);
  const [checked, setChecked] = useState(false);
  const [submitValue, setSubmitValue] = useState(true);
  const [isForgot, setIsForgot] = useState(false);
  const [forgotMessage, setForgotMessage] = useState(false);
  const dispatch = useDispatch();

  const isChecked = (evt) => {
    setChecked(!checked);
    setSubmitValue(!submitValue);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const username = evt.target.username.value;
    const password = evt.target.password.value;

    if (checked) {
      dispatch(authenticate(username, password, 'login'));
    } else {
      {
        <div>Please check box</div>;
      }
    }
  };

  const forgotPassword = (evt) => {
    evt.preventDefault();

    const email = evt.target.email.value;
    if (email) {
      setForgotMessage(true);
      evt.target.email.value = '';
      dispatch(fetchUserPassword(email));
    }
  };

  return (
    <div>
      <div className="container auth">
        <h3 className="make-me-blue">FACILITY AND CONSULTANT LOGIN</h3>
        <form className="auth-form" onSubmit={handleSubmit}>
          <div className="username-field">
            <label htmlFor="username">Username:</label>
            <input name="username" type="text" />
          </div>
          <div className="password-field">
            <label htmlFor="password">Password:</label>
            <input name="password" type="password" />
          </div>
          <p>
            <strong>CONFIDENTIAL/HIPPA information:</strong> This website
            contains confidential information that is intended only for the use
            of the individual and/or the entity for which it is intended. If you
            are not the intended user, disclosure, copying, use, or distribution
            of the information included in this website is prohibited by federal
            and state law.
          </p>
          <p>
            By checking the box, you agree to the above confidential information
            statement.
          </p>
          <input
            className="login-checkbox"
            name="checkbox"
            type="checkbox"
            onChange={isChecked}
          />
          <div>
            <button
              className="btn btn-default login-button"
              type="submit"
              name="submit"
              disabled={submitValue}
            >
              Login
            </button>
          </div>

          {error && error.response && <div> {error.response.data} </div>}
        </form>
      </div>
      <div className="login-forgot-div">
        <a onClick={() => setIsForgot(!isForgot)}>Forgot Username/Password?</a>
        {isForgot ? (
          <form className="login-forgot-sub" onSubmit={forgotPassword}>
            <label>Please enter the email associated with your account:</label>
            <input
              className="login-forgot-sub-field"
              type="text"
              name="email"
            />
            <button
              className="btn btn-default login-button"
              type="submit"
              name="submit"
            >
              Submit
            </button>
            {forgotMessage ? (
              <p style={{ color: 'green' }}>
                Successfully sent! Please check your email.
              </p>
            ) : (
              ''
            )}
          </form>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
