import React from 'react';
import { useSelector } from 'react-redux';

export const Popup = (props) => {
  const isLoggedIn = useSelector((state) => !!state.auth.id);
  const isAdmin = useSelector((state) => state.auth.isAdmin);

  return props.trigger && isLoggedIn ? (
    <div className="popup">
      <div className="popup-inner">
        <h3>Warning!</h3>
        <p>Your session is about to expire due to inactivity.</p>
        {props.children}
      </div>
    </div>
  ) : (
    ''
  );
};
