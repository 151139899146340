import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export const Admin = () => {
  const { username } = useSelector((state) => state.auth);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="admin-dashboard">
      <h1 className="header-admin">Admin Dashboard</h1>
      <h2>Hey {username}!</h2>
      <div className="admin-dashboard-item">
        <Link to="/users">
          <button className="btn btn-default dashboard-button">
            <h4>USERS</h4>
          </button>
        </Link>
      </div>
      <div className="admin-dashboard-item">
        <Link to="/facilities">
          <button className="btn btn-default dashboard-button">
            <h4>FACILITIES</h4>
          </button>
        </Link>
      </div>
      <div className="admin-dashboard-item">
        <button className="btn btn-default dashboard-button">
          <a
            href="https://www.hostinger.com/cpanel-login"
            role="link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h4 className="black-text">EMAIL</h4>
          </a>
        </button>
      </div>
    </div>
  );
};
