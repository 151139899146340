import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { logout } from '../store';
import { useDispatch, useSelector } from 'react-redux';

const Navbar = () => {
  const isLoggedIn = useSelector((state) => !!state.auth.id);
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const dispatch = useDispatch();
  const [menuName, setMenuName] = useState('Open Menu');

  const handleClick = () => {
    dispatch(logout());
  };

  function handleMobileMenu() {
    const sideMenu = document.querySelector('.menu-links');
    if (sideMenu.style.visibility === 'hidden') {
      sideMenu.style.visibility = 'visible';
      setMenuName('Close Menu');
    } else {
      sideMenu.style.visibility = 'hidden';
      setMenuName('Open Menu');
    }
  }

  return (
    <div className="navbar navbar-default navbar-fixed-top navbar-expand-lg">
      <Link to="/home">
        <img className="main-logo" src="/main-logo-transparent.png" />
      </Link>
      <nav
        className="container-fluid navbar-expand-lg collapse navbar-collapse"
        id="navbarNavAltMarkup"
      >
        {isLoggedIn && isAdmin ? (
          <div>
            <a
              onClick={handleMobileMenu}
              className="link-item nav-item nav-link mobile-menu"
            >
              {menuName}
            </a>
            <div className="menu-links navbar-nav">
              {/* User is logged in and is Admin */}
              <Link className="link-item nav-item nav-link active" to="/home">
                HOME
              </Link>
              <div className="dropdown-mine nav-item nav-link">
                <a className="link-item">ABOUT</a>
                <div className="dropdown-content">
                  <Link className="dropdown-link" to="/about">
                    OUR TEAM
                  </Link>
                  <Link className="dropdown-link" to="/careers">
                    CAREERS
                  </Link>
                </div>
              </div>
              <div className="dropdown-mine nav-item nav-link">
                <a className="link-item">SERVICES</a>
                <div className="dropdown-content">
                  <Link className="dropdown-link" to="/pharmacy-services">
                    PHARMACIES
                  </Link>
                  <Link className="dropdown-link" to="/facility-services">
                    FACILITIES
                  </Link>
                </div>
              </div>
              <Link className="link-item nav-item nav-link" to="/contact">
                CONTACT
              </Link>
              <div className="dropdown-mine nav-item nav-link">
                <a className="link-item">RESOURCES</a>
                <div className="dropdown-content">
                  <Link className="dropdown-link" to="/clinical-resources">
                    CLINICAL RESOURCES
                  </Link>
                  <Link className="dropdown-link" to="/regulatory">
                    REGULATORY RESOURCES
                  </Link>
                  <Link className="dropdown-link" to="/antibiotic-stewardship">
                    ANTIBIOTIC STEWARDSHIP
                  </Link>
                  <Link className="dropdown-link" to="/forms-resources">
                    FORMS
                  </Link>
                </div>
              </div>
              <Link className="link-item nav-item nav-link" to="/portal">
                PORTAL
              </Link>
              <Link className="link-item nav-item nav-link" to="/admin">
                ADMIN
              </Link>
              <div className="dropdown-mine nav-item nav-link">
                <a className="link-item">ACCOUNT</a>
                <div className="dropdown-content">
                  <a
                    href="https://hostinger.titan.email/login/"
                    role="link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    EMAIL
                  </a>
                  <Link className="dropdown-link" to="/account">
                    SETTINGS
                  </Link>
                  <a className="dropdown-link" href="#" onClick={handleClick}>
                    LOG OUT
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : isLoggedIn ? (
          <div>
            <a
              onClick={handleMobileMenu}
              className="link-item nav-item nav-link mobile-menu"
            >
              {menuName}
            </a>
            <div className="menu-links navbar-nav">
              {/* User is logged in */}
              <Link className="link-item nav-item nav-link active" to="/home">
                HOME
              </Link>
              <div className="dropdown-mine nav-item nav-link">
                <a className="link-item">ABOUT</a>
                <div className="dropdown-content">
                  <Link className="dropdown-link" to="/about">
                    OUR TEAM
                  </Link>
                  <Link className="dropdown-link" to="/careers">
                    CAREERS
                  </Link>
                </div>
              </div>
              <div className="dropdown-mine nav-item nav-link">
                <a className="link-item">SERVICES</a>
                <div className="dropdown-content">
                  <Link className="dropdown-link" to="/pharmacy-services">
                    PHARMACIES
                  </Link>
                  <Link className="dropdown-link" to="/facility-services">
                    FACILITIES
                  </Link>
                </div>
              </div>
              <Link className="link-item nav-item nav-link" to="/contact">
                CONTACT
              </Link>
              <div className="dropdown-mine nav-item nav-link">
                <a className="link-item">RESOURCES</a>
                <div className="dropdown-content">
                  <Link className="dropdown-link" to="/clinical-resources">
                    CLINICAL RESOURCES
                  </Link>
                  <Link className="dropdown-link" to="/regulatory">
                    REGULATORY RESOURCES
                  </Link>
                  <Link className="dropdown-link" to="/antibiotic-stewardship">
                    ANTIBIOTIC STEWARDSHIP
                  </Link>
                  <Link className="dropdown-link" to="/forms-resources">
                    FORMS
                  </Link>
                </div>
              </div>
              <Link className="link-item nav-item nav-link" to="/portal">
                PORTAL
              </Link>
              <div className="dropdown-mine nav-item nav-link">
                <a className="link-item">ACCOUNT</a>
                <div className="dropdown-content">
                  <a
                    href="https://hostinger.titan.email/login/"
                    role="link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    EMAIL
                  </a>
                  <Link className="dropdown-link" to="/account">
                    SETTINGS
                  </Link>
                  <a className="dropdown-link" href="#" onClick={handleClick}>
                    LOG OUT
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <a
              onClick={handleMobileMenu}
              className="link-item nav-item nav-link mobile-menu"
            >
              {menuName}
            </a>
            <div className="menu-links navbar-nav">
              {/* User is logged in */}
              <Link className="link-item nav-item nav-link active" to="/home">
                HOME
              </Link>
              <div className="dropdown-mine nav-item nav-link">
                <a className="link-item">ABOUT</a>
                <div className="dropdown-content">
                  <Link className="dropdown-link" to="/about">
                    OUR TEAM
                  </Link>
                  <Link className="dropdown-link" to="/careers">
                    CAREERS
                  </Link>
                </div>
              </div>
              <div className="dropdown-mine nav-item nav-link">
                <a className="link-item">SERVICES</a>
                <div className="dropdown-content">
                  <Link className="dropdown-link" to="/pharmacy-services">
                    PHARMACIES
                  </Link>
                  <Link className="dropdown-link" to="/facility-services">
                    FACILITIES
                  </Link>
                </div>
              </div>
              <Link className="link-item nav-item nav-link" to="/contact">
                CONTACT
              </Link>
              <div className="dropdown-mine nav-item nav-link">
                <a className="link-item">RESOURCES</a>
                <div className="dropdown-content">
                  <Link className="dropdown-link" to="/clinical-resources">
                    CLINICAL RESOURCES
                  </Link>
                  <Link className="dropdown-link" to="/regulatory">
                    REGULATORY RESOURCES
                  </Link>
                  <Link className="dropdown-link" to="/antibiotic-stewardship">
                    ANTIBIOTIC STEWARDSHIP
                  </Link>
                  <Link className="dropdown-link" to="/forms-resources">
                    FORMS
                  </Link>
                </div>
              </div>
              <Link className="link-item nav-item nav-link" to="/login">
                LOGIN
              </Link>
            </div>
          </div>
        )}
      </nav>
    </div>
  );
};

export default Navbar;
