import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  fetchFacilities,
  addFacility,
  deleteTheFacility,
  updateTheFacility,
} from '../store/all-facilities';
import { useSelector, useDispatch } from 'react-redux';

export const AllFacilities = () => {
  const dispatch = useDispatch();
  const [deleteMe, setDeleteMe] = useState(false);
  const [updateMe, setUpdateMe] = useState(false);
  const [facilityId, setFacilityId] = useState(0);
  const [formAction, setFormAction] = useState('');
  const [formStatus, setFormStatus] = useState(false);

  const allTheFacilities = useSelector((state) => state.facilities);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchFacilities());
  }, []);

  const handleFacilityAdd = (evt) => {
    evt.preventDefault();
    const facilityName = evt.target.name.value;

    if (facilityName) {
      dispatch(addFacility(facilityName));
      setFormStatus(true);
      setTimeout(() => {
        setFormStatus(false);
      }, 3000);
      evt.target.name.value = '';
    }
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();

    if (formAction === 'delete') {
      dispatch(deleteTheFacility(facilityId));
      setDeleteMe(false);
      setFormAction('');
      setFormStatus(true);
      setTimeout(() => {
        setFormStatus(false);
      }, 3000);
    } else if (formAction === 'update') {
      const newName = evt.target.name.value;
      dispatch(updateTheFacility([facilityId, newName]));
      setUpdateMe(false);
      setFormAction('');
      setFormStatus(true);
      setTimeout(() => {
        setFormStatus(false);
      }, 3000);
    }
  };

  return (
    <div className="admin-users-div">
      <div className="back-to-dashboard-div2">
        <Link to="/admin" className="back-to-dashboard">
          {'⬅ back to dashboard'}
        </Link>
      </div>

      <div>
        <h1 className="header-admin">Resident First Admin - Facilities</h1>
      </div>
      <div>
        <h3>Add Facility to Database:</h3>
        <form onSubmit={handleFacilityAdd}>
          <label>Facility Name</label>
          <input className="larger-box-size" type="text" name="name" />
          <button>Add Facility</button>
        </form>

        <div className="success-form-center">
          {formStatus ? (
            <div class="alert alert-success alert-pop-up" role="alert">
              <strong>Success!</strong>
            </div>
          ) : (
            ''
          )}
        </div>
        <div>
          <h4>All Facilities:</h4>
          <div className="main-table-div">
            {allTheFacilities.map((facility) => {
              return (
                <div key={facility.id}>
                  <form onSubmit={handleSubmit} key={facility.id}>
                    <label className="admin-edit-user-titles">ID: </label>
                    <h5 className="admin-edit-user-titles">{facility.id}</h5>
                    <label className="admin-edit-user-titles">
                      Facility Name:{' '}
                    </label>
                    <input
                      className="admin-edit-user-titles admin-facility-name"
                      name="name"
                      placeholder={facility.name}
                    />
                    <Link to={`/facilities/${facility.id}`}>View Users</Link>
                    <button
                      onClick={() => {
                        setUpdateMe(true);
                        setFacilityId(facility.id);
                      }}
                    >
                      Update
                    </button>
                    <button
                      onClick={() => {
                        setDeleteMe(true);
                        setFacilityId(facility.id);
                      }}
                    >
                      Delete
                    </button>
                    {deleteMe && facility.id === facilityId ? (
                      <div>
                        <p>
                          Are you sure you want to DELETE facility:{' '}
                          {facility.name} and all of the users associated with
                          it?
                        </p>
                        <button onClick={() => setFormAction('delete')}>
                          Confirm Delete
                        </button>
                        <button onClick={() => setDeleteMe(false)}>
                          Cancel
                        </button>
                      </div>
                    ) : (
                      ''
                    )}
                    {updateMe && facility.id === facilityId ? (
                      <div>
                        <p>
                          Are you sure you want to UPDATE this facility: {''}
                          {facility.name}?
                        </p>
                        <button onClick={() => setFormAction('update')}>
                          Confirm Update
                        </button>
                        <button onClick={() => setUpdateMe(false)}>
                          Cancel
                        </button>
                      </div>
                    ) : (
                      ''
                    )}
                  </form>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
