import React, { useState, useEffect } from 'react';
import { fetchUsers, deleteTheUser, updateTheUser } from '../store/all-users';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { authenticate } from '../store';
import { fetchFacilities } from '../store/all-facilities';
import { addUser } from '../store/all-users';

export const AllUsers = () => {
  const error = useSelector((state) => state.auth.error);
  const dispatch = useDispatch();
  const allTheUsers = useSelector((state) => state.users);
  const [username, setUsername] = useState('');
  const [userId, setUserId] = useState(0);
  const [deleteMe, setDeleteMe] = useState(false);
  const [updateMe, setUpdateMe] = useState(false);
  const [formAction, setFormAction] = useState('');
  const [formStatus, setFormStatus] = useState(false);
  const [selectStatus, setSelectStatus] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchUsers());
    dispatch(fetchFacilities());
  }, []);

  const handleAccount = (evt) => {
    evt.preventDefault();
    let username = evt.target.username;
    let email = evt.target.email;
    let select = evt.target.select;

    if (username.value && email.value && select.value) {
      if (select.value === 'Consultant') {
        dispatch(addUser(username.value, email.value, true, false));
        username.value = '';
        email.value = '';
        select.value = 'select';
        setFormStatus(true);
        setTimeout(() => {
          setFormStatus(false);
        }, 3000);
      } else if (select.value === 'Nurse') {
        dispatch(addUser(username.value, email.value, false, true));
        username.value = '';
        email.value = '';
        select.value = 'select';
        setFormStatus(true);
        setTimeout(() => {
          setFormStatus(false);
        }, 3000);
      } else {
        setSelectStatus(true);
        setTimeout(() => {
          setSelectStatus(false);
        }, 3000);
      }
    } else {
      setSelectStatus(true);
      setTimeout(() => {
        setSelectStatus(false);
      }, 3000);
    }
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    let newUsername = evt.target.username.value;
    let newPassword = evt.target.password.value;

    if (formAction === 'delete') {
      dispatch(deleteTheUser(userId));
      setDeleteMe(false);
      setFormAction('');
      setFormStatus(true);
      setTimeout(() => {
        setFormStatus(false);
      }, 3000);
    } else if (formAction === 'update') {
      if (newUsername && newPassword) {
        dispatch(updateTheUser([userId, 'both', newUsername, newPassword]));
        setUpdateMe(false);
        setFormAction('');
        setFormStatus(true);
        newUsername = '';
        newPassword = '';
        setTimeout(() => {
          setFormStatus(false);
        }, 3000);
      } else if (newUsername && !newPassword) {
        dispatch(updateTheUser([userId, 'username', newUsername]));
        setUpdateMe(false);
        setFormAction('');
        setFormStatus(true);
        newUsername = '';
        setTimeout(() => {
          setFormStatus(false);
        }, 3000);
      } else if (!newUsername && newPassword) {
        dispatch(updateTheUser([userId, 'password', username, newPassword]));
        setUpdateMe(false);
        setFormAction('');
        setFormStatus(true);
        newPassword = '';
        setTimeout(() => {
          setFormStatus(false);
        }, 3000);
      } else {
        alert(
          'Cannot update user. Please ensure all fields are filled out properly and you have refreshed the page.'
        );
      }
    }
  };

  return (
    <div className="admin-users-div">
      <div className="back-to-dashboard-div2">
        <Link to="/admin" className="back-to-dashboard">
          {'⬅ back to dashboard'}
        </Link>
      </div>
      <h1 className="header-admin">Resident First Admin - Users</h1>
      <div>
        <form onSubmit={handleAccount}>
          <h4>Create User Account:</h4>
          <label>Username:</label>
          <input className="larger-box-size" type="text" name="username" />
          <label>Email:</label>
          <input className="larger-box-size" type="text" name="email" />
          <label>Account Type</label>
          <select name="select">
            <option>select</option>
            <option>Consultant</option>
            <option>Nurse</option>
          </select>
          <button>Add Account</button>
          {error && error.response && <div> {error.response.data} </div>}
        </form>
      </div>
      <div className="success-form-center">
        {formStatus ? (
          <div class="alert alert-success alert-pop-up" role="alert">
            <strong>Success!</strong>
          </div>
        ) : (
          ''
        )}
        {selectStatus ? (
          <div class="alert alert-danger alert-pop-up" role="alert">
            <strong>Please fill out all fields</strong>
          </div>
        ) : (
          ''
        )}
      </div>
      <div>
        <h4>All user accounts:</h4>
        <div className="main-table-div">
          {allTheUsers.map((user) => {
            return (
              <div key={user.id}>
                <form onSubmit={handleSubmit} key={user.id}>
                  {user.isConsultant ? (
                    <div className="admin-usertype-consultant">CONSULTANT</div>
                  ) : user.isNurse ? (
                    <div className="admin-usertype-nurse">NURSE</div>
                  ) : (
                    <div className="admin-usertype-admin">*ADMIN*</div>
                  )}
                  <label className="admin-edit-user-titles">ID: </label>
                  <h5 name="userId" className="admin-edit-user-titles">
                    {user.id}
                  </h5>
                  <label className="admin-edit-user-titles">Username: </label>
                  <input
                    name="username"
                    type="text"
                    placeholder={user.username}
                    className="admin-edit-user-titles admin-facility-name"
                  />
                  <label className="admin-edit-user-titles">Password: </label>
                  <input
                    name="password"
                    type="text"
                    className="admin-edit-user-titles"
                  />
                  <Link
                    to={`/users/${user.id}`}
                    className="admin-edit-user-titles"
                  >
                    Assign Facilities
                  </Link>
                  <button
                    onClick={() => {
                      setUsername(user.username);
                      setUpdateMe(true);
                      setUserId(user.id);
                    }}
                  >
                    Update
                  </button>
                  <button
                    onClick={() => {
                      setDeleteMe(true);
                      setUserId(user.id);
                    }}
                  >
                    Delete
                  </button>
                  {deleteMe && user.id === userId ? (
                    <div>
                      <p>
                        Are you sure you want to DELETE this user: {''}
                        {user.username}?
                      </p>
                      <button onClick={() => setFormAction('delete')}>
                        Confirm Delete
                      </button>
                      <button onClick={() => setDeleteMe(false)}>Cancel</button>
                    </div>
                  ) : (
                    ''
                  )}
                  {updateMe && user.id === userId ? (
                    <div>
                      <p>
                        Are you sure you want to UPDATE this user: {''}
                        {user.username}?
                      </p>
                      <button onClick={() => setFormAction('update')}>
                        Confirm Update
                      </button>
                      <button onClick={() => setUpdateMe(false)}>Cancel</button>
                    </div>
                  ) : (
                    ''
                  )}
                </form>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
