import React, { useEffect } from 'react';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-google';

export const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    loadReCaptcha();
  }, []);

  const handleSubmit = (evt) => {
    evt.preventDefault();

    let name = evt.target.name;
    let email = evt.target.email;
    let subject = evt.target.subject;
    let message = evt.target.message;

    let formData = {
      name: name.value,
      email: email.value,
      subject: subject.value,
      message: message.value,
    };

    let isChecked = false;

    const recaptcha = $('#g-recaptcha-response').val();
    if (recaptcha === '') {
      alert('Please check the recaptcha');
      return;
    } else {
      isChecked = true;
    }

    if (isChecked) {
      let xhr = new XMLHttpRequest();
      xhr.open('POST', '/forms', true);
      xhr.setRequestHeader('content-type', 'application/json');
      xhr.onload = function () {
        if (xhr.responseText === 'success') {
          alert('Email sent successfully!');
          window.location.reload();
        } else {
          alert('Please check that all fields are filled out');
        }
      };
      xhr.send(JSON.stringify(formData));
    } else {
      alert('Please check that all fields are correct');
    }
  };

  return (
    <div className="login-header">
      <div className="header-main contact-form">
        <h1>CONTACT US</h1>
        <hr className="header-line-about" />
        <div className="contact-form">
          <h3>Please fill out all fields</h3>
          <form onSubmit={handleSubmit}>
            <label>Full Name</label>
            <input name="name" type="text" className="form-items" />
            <label>Your Email</label>
            <input name="email" type="email" className="form-items" />
            <label>Subject</label>
            <input name="subject" type="text" className="form-items" />
            <label>Message</label>
            <textarea name="message" className="form-items message-box" />
            <label>Check the box before submitting</label>
            <ReCaptcha
              size="visible"
              render="explicit"
              sitekey={process.env.REACT_APP_CAPTCHA_KEY}
            />
            <button className="btn btn-default contact-button">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};
