import axios from 'axios';

const SHOW_USERS_FACILITIES = 'SHOW_USERS_FACILITIES';

const showUsersFacilities = (facilities) => {
  return {
    type: SHOW_USERS_FACILITIES,
    usersFacilities: facilities,
  };
};

export const fetchUsersFacilities = (userID) => {
  return async (dispatch) => {
    try {
      const response = await axios.get('/api/portal', {
        params: {
          userID,
        },
      });
      const specificFacilities = response.data;

      dispatch(showUsersFacilities(specificFacilities));
    } catch (error) {
      console.log('Sorry not able to fetch facilities', error);
    }
  };
};

export default function allUsersFacilitiesReducer(state = [], action) {
  switch (action.type) {
    case SHOW_USERS_FACILITIES:
      return action.usersFacilities;
    default:
      return state;
  }
}
